import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { FiCheck } from 'react-icons/fi';
import Select from 'react-select';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
} from 'react-share';
import SwiperCore, { Thumbs, Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Container from 'components/Container/Container';
import IconText from 'components/IconText/IconText';
import DeliveryTruckStatic from 'components/Icons/Project/DeliveryTruckStatic';
import Quality from 'components/Icons/Project/Quality';
import Security from 'components/Icons/Project/Security';
import ShoppingBag from 'components/Icons/Project/ShoppingBag';
import Layout from 'components/Layout';
import OffersCarousel from 'components/OffersCarousel/OffersCarousel';
import Price from 'components/Price/Price';
import Seo from 'components/Seo';
import TitleBrand from 'components/TitleBrand/TitleBrand';

import useData from 'data/useData';

import useCart from 'hooks/useCart';

import { options } from 'utilities/ReactSelect/SelectUtils';
// import fbqEvent from 'utilities/fbq';
import dataLayerPush from 'utilities/dataLayer';

import CartStore from 'store/cart';

import 'swiper/swiper.scss';

import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/zoom/zoom.scss';

import { hrLightFull } from 'styles/components/hr.module.scss';
import { linkUnderlined } from 'styles/components/links.module.scss';
import * as styles from 'styles/pages/product.module.scss';
import { imageContainer } from 'styles/pages/product.module.scss';

import 'styles/overrides/react-select.scss';
import 'styles/overrides/swiper-product.scss';
import 'styles/pages/product.scss';

SwiperCore.use([Thumbs, Zoom]);

const thumbs = {
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  slidesPerView: 'auto',
  spaceBetween: 16,
  className: 'swiper-product-thumbs',
};

const settings = {
  spaceBetween: 50,
  slidesPerView: 1,
  zoom: true,
  autoHeight: true,
  className: 'swiper-product-container',
};

const hrMargin = { margin: '1rem 0' };
const mbL = { marginBottom: '5rem' };
const mbM = { marginBottom: '3.5rem' };
const titleStyle = { fontSize: '1rem', marginBottom: '0.75rem' };
const imgStyle = { height: 'auto' };
const imgContainerStyle = { overflow: 'visible' };

export default function ProductPage({
  data: { strapiProduct: product, site },
  pageContext: { category },
}) {
  const { data: relatedProducts } = useData(
    `/products/related?category=${category.strapiId}&sku=${product.sku}`
  );
  const [showSuccess, setShowSuccess] = useState(false);
  const [quantity, setQuantity] = useState(options[0]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { totals } = useCart();
  const freeTransfer =
    totals.priceWithoutFee && totals.priceWithoutFee.split('.')[0];

  const handleSelect = (value) => {
    setQuantity(value);
  };

  const addToCart = () => {
    CartStore.addCartItem({ ...product }, quantity.value);
    setShowSuccess(true);
    try {
      // fbqEvent('track', 'AddToCart', {
      //   value: product.finalPrice / 100,
      //   currency: 'EUR',
      //   content_type: 'product',
      //   content_ids: product.sku,
      // });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: [
              {
                name: product.title,
                id: product.sku || product.id,
                price: product.finalPrice / 100,
                category: category.title,
                quantity: quantity,
              },
            ],
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      // fbqEvent('track', 'ViewContent', {
      //   value: product.finalPrice / 100,
      //   currency: 'EUR',
      //   content_type: 'product',
      //   content_ids: product.sku,
      // });
      dataLayerPush({ ecommerce: null });
      dataLayerPush({
        ecommerce: {
          detail: {
            products: [
              {
                name: product.title,
                id: product.sku || product.id,
                price: product.finalPrice / 100,
                category: category.title,
              },
            ],
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let mounted = true;
    if (showSuccess === true) {
      setTimeout(() => {
        mounted && setShowSuccess(false);
      }, 3000);
    }
    return () => {
      mounted = false;
    };
  }, [showSuccess]);

  return (
    <Layout fullWidth={true}>
      <Seo
        title={`${product.title} | ${category.title}${
          category.strapiParent && ' | ' + category.strapiParent.title
        }`}
        description={
          product.description ||
          `Δείτε ${product.title} στην κατηγορία ${category.title}. Χειροποίητα παραδοσιακά Κρητικά κοσμήματα υψηλής ποιότητας.`
        }
        meta={product.images.map((image) => {
          return { property: 'og:image', content: image.url };
        })}
      />
      <Container containerClass={styles.mainContainer}>
        <div className={styles.breadcrumbs}>
          <Link className={`${linkUnderlined} ${styles.link}`} to="/">
            ΑΡΧΙΚΗ
          </Link>
          <span className={styles.dot}>&middot;</span>
          {category.strapiParent && category.strapiParent.slug && (
            <>
              <Link
                className={`${linkUnderlined} ${styles.link}`}
                to={`/${category.strapiParent.slug}`}
              >
                {category.strapiParent.title}
              </Link>
              <span className={styles.dot}>&middot;</span>
            </>
          )}
          <Link
            className={`${linkUnderlined} ${styles.link}`}
            to={`${
              category.strapiParent &&
              category.strapiParent.slug &&
              '/' + category.strapiParent.slug
            }/${category.slug}`}
          >
            {category.title}
          </Link>
          <span className={styles.dot}>&middot;</span>
          <Link
            className={`${linkUnderlined} ${styles.link} ${styles.active}`}
            to={product.relativePath}
          >
            {product.title}
          </Link>
        </div>
        <div className={styles.container}>
          {product && product.images && product.images.length > 0 && (
            <div className={styles.imgContainer}>
              <Swiper
                thumbs={{
                  swiper: thumbsSwiper,
                }}
                {...settings}
              >
                {product.localFile.map((image, i) => (
                  <div key={i}>
                    <SwiperSlide key={i}>
                      <GatsbyImage
                        className={`swiper-zoom-container ${imageContainer}`}
                        style={imgContainerStyle}
                        imgStyle={imgStyle}
                        image={getImage(image)}
                        alt={`${product.title} ' - ' ${category.title}`}
                      />
                    </SwiperSlide>
                  </div>
                ))}
              </Swiper>
              <Swiper onSwiper={setThumbsSwiper} {...thumbs}>
                {product.localFile.map((image, i) => (
                  <SwiperSlide key={i}>
                    <GatsbyImage
                      className={imageContainer}
                      image={getImage(image)}
                      alt={`${product.title} ' - ' ${category.title}`}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <div className={styles.details}>
            <h1 className={styles.title}>{product.title}</h1>
            <h2 className={styles.categoryTitle}>{category.title}</h2>
            {product.material && product.material.title ? (
              <h3 className={styles.sku}>Υλικό: {product.material.title}</h3>
            ) : null}
            <h3 className={styles.sku}>Κωδικός προϊόντος: {product.sku}</h3>
            <h3 className={styles.available}>
              <IconContext.Provider value={{ size: '16px' }}>
                <FiCheck style={{ marginRight: '2px' }} />
              </IconContext.Provider>
              ΑΜΕΣΑ ΔΙΑΘΕΣΙΜΟ
            </h3>
            <Price
              beginPrice={product.beginPrice}
              finalPrice={product.finalPrice}
              fpClass={styles.fpClass}
              bpClass={styles.bpClass}
            />
            {product.description && (
              <p className={styles.description}>{product.description}</p>
            )}
            <h4 className={styles.optionTitle}>ΠΟΣΟΤΗΤΑ</h4>
            <div className={styles.addToCartContainer}>
              <Select
                className={styles.select}
                classNamePrefix="react-select"
                value={quantity}
                placeholder="Ποσότητα"
                label="Ποσότητα"
                onChange={handleSelect}
                options={options}
                isSearchable={false}
              />
              <button
                type="submit"
                className={styles.btnCart}
                onClick={addToCart}
              >
                <ShoppingBag fill="#000" />
                ΠΡΟΣΘΗΚΗ ΣΤΟ ΚΑΛΑΘΙ
              </button>
            </div>
            <p
              className={`${styles.successMsg}${
                showSuccess ? ' ' + styles.active : ''
              }`}
            >
              Το προϊόν προστέθηκε επιτυχώς στο καλάθι!
            </p>
            <div className={styles.socialContainer}>
              <FacebookShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
              >
                <FacebookIcon
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#c5a557"
                  size={44}
                  round={true}
                />
              </FacebookShareButton>
              <TwitterShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
              >
                <TwitterIcon
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#c5a557"
                  size={44}
                  round={true}
                />
              </TwitterShareButton>
              <ViberShareButton
                className={styles.socialBtn}
                url={`${site.siteMetadata.url}${product.relativePath}`}
              >
                <ViberIcon
                  bgStyle={{ fill: '#fff' }}
                  iconFillColor="#c5a557"
                  size={44}
                  round={true}
                />
              </ViberShareButton>
            </div>
            <div className={styles.infoSection}>
              <IconText
                title="ΥΨΗΛΗ ΠΟΙΟΤΗΤΑ ΥΛΙΚΩΝ"
                titleStyle={titleStyle}
                icon={<Quality />}
                text="σε κάθε κατασκευή μας"
                iconSize="small"
              />
              <hr className={hrLightFull} style={hrMargin} />
              <IconText
                title="ΔΩΡΕΑΝ ΜΕΤΑΦΟΡΙΚΑ"
                titleStyle={titleStyle}
                icon={<DeliveryTruckStatic />}
                text={`για αγορές άνω των ${freeTransfer || '150'}€`}
                iconSize="small"
              />
              <hr className={hrLightFull} style={hrMargin} />
              <IconText
                title="ΑΣΦΑΛΕΙΑ ΣΥΝΑΛΛΑΓΩΝ"
                titleStyle={titleStyle}
                icon={<Security />}
                text="για την προστασία των ηλεκτρονικών σας πληρωμών"
                iconSize="small"
              />
            </div>
          </div>
        </div>
      </Container>
      {relatedProducts && relatedProducts.length > 0 && (
        <>
          <Container containerClass={styles.mainContainer}>
            <hr className={hrLightFull} style={mbL} />
          </Container>
          <section style={mbM}>
            <OffersCarousel
              title={<TitleBrand text="Νέα Προϊόντα" />}
              products={relatedProducts}
            />
          </section>
        </>
      )}
    </Layout>
  );
}

export const query = graphql`
  query SingleProduct($id: String!) {
    site {
      siteMetadata {
        url
      }
    }
    strapiProduct(strapiId: { eq: $id }) {
      id: strapiId
      finalPrice
      beginPrice
      description
      enabled
      kind {
        title
      }
      offerType
      relativePath
      sku
      slug
      sticker
      title
      material {
        title
      }
      mainCategory {
        slug
        title
      }
      subCategory {
        slug
        title
      }
      images {
        hash
        url
      }
      localFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100)
        }
      }
    }
  }
`;
