// extracted by mini-css-extract-plugin
export var mainContainer = "product-module--mainContainer--fzhpc";
export var breadcrumbs = "product-module--breadcrumbs--3_x7p";
export var link = "product-module--link--3DB7w";
export var active = "product-module--active--3fTfo";
export var dot = "product-module--dot--1J6eT";
export var container = "product-module--container--cXF-d";
export var imgContainer = "product-module--imgContainer--1ZVsJ";
export var details = "product-module--details--1iTVQ";
export var imageContainer = "product-module--imageContainer--ExoIK";
export var title = "product-module--title--3ePMi";
export var categoryTitle = "product-module--categoryTitle--1AdrT";
export var sku = "product-module--sku--3pMZs";
export var available = "product-module--available--2rMO0";
export var fpClass = "product-module--fpClass--ebnvI";
export var bpClass = "product-module--bpClass--RprI2";
export var description = "product-module--description--19kuS";
export var optionTitle = "product-module--option-title--35fkA";
export var addToCartContainer = "product-module--add-to-cart-container--2hNFE";
export var select = "product-module--select--81BQT";
export var btnCart = "product-module--btn-cart--131RT";
export var successMsg = "product-module--success-msg--PI_Rz";
export var errorMsg = "product-module--error-msg--3ADhp";
export var socialContainer = "product-module--socialContainer--1kQcV";
export var socialBtn = "product-module--socialBtn--2Qodh";
export var infoSection = "product-module--info-section--C7fj1";