const dataLayerPush = (data) => {
  if (typeof window !== 'undefined') {
    if (!window.dataLayer) {
      return;
    }
    window.dataLayer.push(data);
  }
};

export default dataLayerPush;
